<template>
    <div>
        <core-view/>
    </div>
</template>

<script>
export default {
    name: 'blank',
    components: {
        CoreView: () => import('@/components/core/View')
    }
}
</script>

<style lang="scss">
    @import '@/assets/scss/theme.scss';
</style>